@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.container {
	@include box-shadow();
	@include basic-flex(column);
	background-color: $bg-grey;
	padding: 1rem 1.5rem;
	border-radius: 1rem;
	width: 90%;
	max-width: 32rem;
	@media screen and (max-width: $maxPhone) {
		width: 94%;
	}
	h3 {
		color: $brand-navy;
	}
	input {
		@include box-shadow-hover-transition();
		background-color: #fff;
		text-align: center;
		font-family: $baloo;
		color: $brand-navy;
		font-weight: 500;
		padding: 5px;
		margin-bottom: 15px;
		line-height: 1.5rem;
		border-radius: 5px;
		width: 80%;
	}
	a {
		color: $dark-grey;
		font-weight: 500;
		text-decoration: underline;
		&:hover {
			color: $brand-navy;
		}
	}
}
