@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.error {
	margin-top: 0;
	height: 1.8rem;
	font-weight: 600;
	font-size: 0.8rem;
	color: $error;
	transition: 0.3s;
	@media screen and (max-width: $maxPhone) {
		font-size: 0.6rem;
	}
}

.loginWithGoogleButton {
	@include basic-flex();
	background: none;
	color: #fff;
	font-weight: 600;
	font-family: $baloo;
	&:hover {
		cursor: pointer;
		text-shadow: 0 3px 3px rgba($color: #000000, $alpha: 0.4);
	}
}

.googleIcon {
	background-size: cover;
	height: 20px;
	width: 20px;
	margin-right: 5px;
}
