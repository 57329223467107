@import '../modules/colors', '../modules/shadows', '../modules/breakpoints', '../modules/containers';

.container {
	@include center-child();
	background-image: linear-gradient(to bottom right, $brandNavy 50%, $brandYellow 150%);
	height: 100vh;
}

.content {
	@include basic-flex(column);
	width: 100%;
	height: 100%;
}
